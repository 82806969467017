import * as z from "zod"

const name = z.string().nonempty()
const username = z
  .string()
  .max(24)
  .refine((username) => username.indexOf(" ") === -1, {
    message: "Username must not contain spaces!",
  })

const email = z.string().email()
const password = z.string().min(6).max(100)
const token = z.string()
const currentPassword = z.string()
const website = z.string()
const youtube = z.string()
const instagram = z.string()
const hackernews = z.string()
const reddit = z.string()
const isPrivate = z.boolean()
const location = z.object({
  id: z.string(),
  name: z.string(),
  lat: z.number(),
  lng: z.number(),
})

export const SignupValidation = z.object({
  name,
  username,
  email,
  password,
  token: token.optional(),
  location: location.optional(),
})

export const LoginValidation = z.object({
  email,
  password,
})

export const ForgotPasswordValidation = z.object({
  email,
})

export const ResetPasswordValidation = z
  .object({
    password,
    passwordConfirmation: z.string().nonempty(),
    token,
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "Passwords don't match",
    path: ["passwordConfirmation"], // set the path of the error
  })

export const ChangePasswordValidation = z.object({
  currentPassword,
  newPassword: password,
})

export const UpdateAccountValidation = z.object({
  name: name.optional(),
  username: username.optional(),
  email: email.optional(),
  password: z
    .string()
    .refine(
      (password) =>
        !password || (password.length && password.length >= 6 && password.length <= 100),
      {
        message: "Password length must be between 6 and 100 characters",
      }
    ),
  website: website.optional(),
  youtube: youtube.optional(),
  instagram: instagram.optional(),
  hackernews: hackernews.optional(),
  reddit: reddit.optional(),
  isPrivate: isPrivate.optional(),
  location: location.optional(),
  description: z.string().max(400).optional(),
})

export const UpdateProfilePhotoValidation = z.object({
  photoUrl: z.string().url().nullable(),
})

export const DisconnectSocialLoginValidation = z.object({
  provider: z.string().nonempty(),
})
