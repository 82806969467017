import segmentPlugin from "@analytics/segment"
import Analytics from "analytics"

const analytics = Analytics({
  app: "frisson",
  plugins: [
    segmentPlugin({
      writeKey: process.env.SEGMENT_WRITE_KEY,
    }),
  ],
})

export default analytics
