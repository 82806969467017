import { useSession } from "@blitzjs/auth"
import { AppProps, ErrorBoundary, ErrorComponent, ErrorFallbackProps } from "@blitzjs/next"
import { useQueryErrorResetBoundary } from "@blitzjs/rpc"
import { AuthenticationError, AuthorizationError } from "blitz"
import analytics from "integrations/analytics"
import Sentry from "integrations/sentry"
import { Router } from "next/router"
import { useEffect } from "react"
import { withBlitz } from "src/blitz-client"
import "tailwindcss/tailwind.css"
import LoginForm from "../auth/components/LoginForm"

// window.onload = () => {
//   analytics.page({
//     title: window.location.pathname,
//     url: window.location.pathname,
//   })
// }

export default withBlitz(AppComponent)

function AppComponent({ Component, pageProps, router }: AppProps) {
  const session = useSession({ suspense: false })

  const getLayout = Component.getLayout || ((page) => page)

  useEffect(() => {
    analytics
      .page({
        title: window.location.pathname,
        url: window.location.pathname,
      })
      .then(() => {})
      .catch(() => {})

    Router.events.on("routeChangeComplete", async (url) => {
      await analytics.page({
        title: url,
        url,
      })
    })
  }, [])

  useEffect(() => {
    if (session.userId) Sentry.setUser({ id: session.userId.toString() })
  }, [session])

  return (
    <ErrorBoundary
      onError={(error, componentStack) => {
        Sentry.captureException(error, { contexts: { react: { componentStack } } })
      }}
      FallbackComponent={RootErrorFallback}
      resetKeys={[router.asPath]}
      onReset={useQueryErrorResetBoundary().reset}
    >
      {getLayout(<Component {...pageProps} />)}
    </ErrorBoundary>
  )
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    return <LoginForm onSuccess={resetErrorBoundary} />
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent
        statusCode={(error as any)?.statusCode || 400}
        title={error.message || error.name}
      />
    )
  }
}
