import { useMutation } from "@blitzjs/rpc"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { FaInfinity } from "react-icons/fa"
import login from "src/auth/mutations/login"
import { LoginValidation } from "../validations"
import { SocialLoginOptions } from "./SocialLoginOptions"

type LoginFormProps = {
  onSuccess?: () => void
  errorMessage?: string
}

export const LoginForm = ({ onSuccess, errorMessage }: LoginFormProps) => {
  const [loginMutation, { isSuccess, isLoading, isError, isIdle }] = useMutation(login)

  const {
    handleSubmit,
    formState: { errors },
    setError,
    register,
  } = useForm({
    resolver: zodResolver(LoginValidation),
  })

  const onSubmit = async (values: any) => {
    try {
      await loginMutation(values)
      onSuccess?.()
    } catch (error: any) {
      if (error.name === "AuthenticationError") {
        setError("password", { message: "Sorry, those credentials are invalid" })
      } else {
        setError("password", {
          message: `Sorry, we had an unexpected error. Please try again. - ${error.toString()}`,
        })
      }
    }
  }

  return (
    <div className="flex min-h-screen flex-col bg-hero-pattern py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <a href="/" title="home">
          <FaInfinity
            className="mx-auto block h-20 w-auto fill-current text-red-500"
            aria-hidden="true"
          />
        </a>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-500">
          Sign in to {process.env.APP_NAME}
        </h2>
        {!!errorMessage && (
          <p className="mt-4 text-center text-md font-semibold text-red-600">{errorMessage}</p>
        )}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  title="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  {...register("email")}
                />
              </div>
              {errors?.email && (
                <div className="mt-1 w-auto text-center text-sm text-red-700">
                  {errors.email.message?.toString()}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  title="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  {...register("password")}
                />
              </div>
              {errors?.password && (
                <div className="mt-1 w-auto text-center text-sm text-red-700">
                  {errors.password.message?.toString()}
                </div>
              )}
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm">
                <a href="forgot-password" className="font-medium text-red-600 hover:text-red-500">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                disabled={isLoading || isSuccess}
              >
                {(isIdle || isError) && "Log in"}
                {(isLoading || isSuccess) && "Logging in..."}
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500">Or continue with</span>
              </div>
            </div>
            <SocialLoginOptions />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
